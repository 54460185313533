import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, CanLoad, Route, Router, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, IAuthStatus } from './auth.service';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  protected currentAuthStatus: IAuthStatus;
  constructor(private authService: AuthService, private router: Router) {
    this.authService.authStatus.subscribe(
      authStatus => (this.currentAuthStatus = (this.authService.getAuthStatus()))
    )
  }

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkPermissions(childRoute);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkPermissions(next);
    }

  protected checkLogin() {
    if((this.authService.getToken() == null || this.authService.getToken() === '')) {
      alert('You must login to continue');
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
  
  protected checkPermissions(route?: ActivatedRouteSnapshot) {
    let roleMatch = true;
    let params: any;
    if(route) {
      const expectedRole = route.data.expectedRole;
      if(expectedRole) {
        roleMatch = this.currentAuthStatus.role === expectedRole;
      }
    }

    if(!roleMatch) {
      alert('You do not have the permissions to view this resource');
      this.router.navigate(['home']);
      return false;
    }
    return true;
  }
}

// export class CanActivateChildGuard implements CanActivateChild {
//   constructor(
//       private readonly router: Router,
//       private readonly location: Location
//   ) {}

//   canActivateChild(route: ActivatedRouteSnapshot): boolean {
//       if (this.dialog.openDialogs.length > 0) {
//           // fix navigation history, see github issue for more details
//           // https://github.com/angular/angular/issues/13586
//           const currentUrlTree = this.router.createUrlTree([], route);
//           const currentUrl = currentUrlTree.toString();
//           this.location.go(currentUrl);

//           this.dialog.closeAll();
//           return false;
//       } else {
//           return true;
//       }
//   }
// }
import { Injectable } from "@angular/core";
import { Role } from "./role.enum";
import {
  Observable,
  BehaviorSubject,
  throwError as observableThrowError,
} from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import * as decode from "jwt-decode";
import { transformError } from "../common/common";
import { CacheService } from "./cache.service";
import { CookieService } from "ngx-cookie-service";
import { Company } from "../types";

@Injectable({
  providedIn: "root",
})
export class AuthService extends CacheService {
  private readonly authProvider: (
    email: string,
    password: string
  ) => Observable<IServerAuthResponse>;
  authStatus = new BehaviorSubject<IAuthStatus>(
    this.getItem("authStatus") || defaultAuthStatus
  );

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) {
    super();

    this.authStatus.subscribe((authStatus) => {
      this.setItem("authStatus", authStatus);
    });

    this.authProvider = this.userAuthProvider;
  }

  private userAuthProvider(
    email: string,
    password: string
  ): Observable<IServerAuthResponse> {
    return this.httpClient.post<IServerAuthResponse>(
      `${environment.urlService}/Acceso`,
      { UserName: email, Password: password },
      {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      }
    );
  }

  authenticate(user, pass) {
    this.logout();
    return this.httpClient
      .post<Company[]>(`${environment.urlService}/ControlAcceso/Autenticar`, {
        UserName: user,
        Password: pass,
      })
      .pipe(catchError(transformError));
  }

  login2(user, pass, urlServidor: string) {
    this.logout();
    return this.httpClient
      .post(`${environment.urlService}/ControlAcceso/acceder`, {
        UserName: user,
        Password: pass,
        urlServidor,
      })
      .pipe(
        map((res: any) => {
          this.setToken(res.token);
          this.setClientUser(res.userName);
          this.setClientUserCode(res.codigo_Usuario);
          this.setCompanyCode(res.id_empresa);
          localStorage.setItem("logged", "1");
          this.authStatus.next({
            role: "login",
          });
          console.log(res);
          return res;
        }),
        catchError(transformError)
      );
  }

  login(email: string, password: string): Observable<IAuthStatus> {
    this.logout();

    const loginResponse = this.authProvider(email, password).pipe(
      map((value) => {
        console.log(value[0]);
        const result = decode(value[0].token);
        return result as IAuthStatus;
      }),
      catchError(transformError)
    );

    loginResponse.subscribe(
      (res) => {
        this.authStatus.next(res);
      },
      (err) => {
        this.logout();
        return observableThrowError(err);
      }
    );
    return loginResponse;
  }

  logout() {
    this.removeItem("jwt");
    //this.removeItem('cart');
    this.removeItem("user");
    this.removeItem("usercode");
    this.removeItem("authStatus");
    this.authStatus.next(defaultAuthStatus);
  }

  private setToken(jwt: string) {
    this.setItem("jwt", jwt);
  }

  private setCompanyCode(code: string) {
    localStorage.setItem("company", code);
  }

  private setClientUser(user: string) {
    localStorage.setItem("user", user);
  }

  private setClientUserCode(code: string) {
    localStorage.setItem("usercode", code);
  }

  getToken(): string {
    return this.getItem("jwt") || "";
  }

  getUser(): string {
    return localStorage.getItem("user") || "";
  }

  getUserCode(): string {
    return localStorage.getItem("usercode") || "";
  }

  getCompanyCode(): string {
    return localStorage.getItem("company") || "";
  }

  private clearToken() {
    this.removeItem("jwt");
    //this.removeItem('cart');
    this.removeItem("user");
    this.removeItem("usercode");
    this.removeItem("authStatus");
  }

  getAuthStatus(): IAuthStatus {
    return this.getItem("authStatus");
  }

  getEmpresas(usuario: string) {
    return this.httpClient
      .get(`${environment.urlService}/EmpresasAutorizadas?Usuario=` + usuario, {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      })
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(transformError)
      );
  }
}

export interface IAuthStatus {
  role: string; //Role
  //primarysid: number,
  //unique_name: string
}

interface IServerAuthResponse {
  token: string;
}

const defaultAuthStatus: IAuthStatus = {
  role: "logout",
  //primarysid: null,
  //unique_name: null
};

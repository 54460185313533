import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './sidenav/sidenav.component';
import { RouterModule } from '@angular/router';
//import { NotFoundComponent } from './not-found/not-found.component';
import { MatSidenavModule, MatListModule, MatIconModule } from '@angular/material';

@NgModule({
  declarations: [SideNavComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule, MatListModule, MatIconModule
  ],
  exports: [
    SideNavComponent,
    //NotFoundComponent
  ]
})
export class SharedModule { }

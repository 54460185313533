import { Injectable } from "@angular/core";
import { Role } from "../auth/role.enum";
import {
  Observable,
  BehaviorSubject,
  throwError as observableThrowError,
} from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import * as decode from "jwt-decode";
import { transformError } from "../common/common";
import { CacheService } from "../auth/cache.service";
import { saveAs } from "file-saver";

@Injectable({
  providedIn: "root",
})
export class AppServices extends CacheService {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public getDeudas(data): Observable<Deudas> {
    return this.httpClient
      .post<Deudas>(
        `${environment.urlService}/ConsultarDeuda/Consulta?`,
        {
          ...{
            Id_empresa: "1",
            Token: "6ecb7eb7-471f-4b84-8938-d5c4cd29aa4f",
            Usuario: "",
            Terminal: "Nombre PC",
            numQuery: "2",
          },
          ...data,
        },
        {
          headers: new HttpHeaders().set("Content-Type", "application/json"),
        }
      )
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(transformError)
      );
  }

  public getInvoice(data) {
    return this.httpClient.post(`${environment.urlService}/FacturaPDF`, data, {
      responseType: "arraybuffer",
      headers: new HttpHeaders().set("Content-Type", "application/json"),
    });
  }

  public getReport(data) {
    return this.httpClient.post(
      `${environment.urlService}/EstadoCuentaClientePDF`,
      data,
      {
        responseType: "arraybuffer",
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      }
    );
  }

  public searchProducts(data) {
    return this.httpClient
      .post(`${environment.urlService}/Productos`, data, {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      })
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(transformError)
      );
  }

  public categories(data) {
    return this.httpClient
      .post(`${environment.urlService}/Familias`, data, {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      })
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(transformError)
      );
  }

  public storeCart(data) {
    return this.httpClient
      .post(
        `${environment.urlService}/ProductoCarritoCompras/MantenimientoCarrito`,
        data,
        {
          headers: new HttpHeaders().set("Content-Type", "application/json"),
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(transformError)
      );
  }

  loadCart(data) {
    return this.httpClient
      .post(
        `${environment.urlService}/ProductoCarritoCompras/CargarCarritoCompra`,
        data,
        {
          headers: new HttpHeaders().set("Content-Type", "application/json"),
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(transformError)
      );
  }

  public placeOrder(data) {
    return this.httpClient.post(
      `${environment.urlService}/CarritoCompras`,
      data,
      {
        responseType: "arraybuffer",
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      }
    );
  }

  public getShoppingHistory(data) {
    return this.httpClient
      .post(`${environment.urlService}/HistoricoPedidosClientes`, data, {
        headers: new HttpHeaders().set("Content-Type", "application/json"),
      })
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(transformError)
      );
  }
}

export class Deudas {
  detalleCxC: detalleCxC[];
  total_Cuotas_cxc: number;
  total_Facturas_cxc: number;
  tipoDeuda: tipoDeuda[];
}

export class detalleCxC {
  numQuery: number;
  cod_Cliente: number;
  nombre_Cliente: string;
  telefono: null;
  linea: number;
  numeroDocumento: number;
  numeroReferencia: number;
  fecha_Documento: string;
  fechaVencimiento: string;
  id_Tipo_Deuda: number;
  descripcionTipoDeuda: null;
  saldo: number;
  dias_Venc: number;
  antiguedad_tramo0: number;
  antiguedad_tramo1: number;
  antiguedad_tramo2: number;
  antiguedad_tramo3: number;
  antiguedad_tramo4: number;
  cod_Servicio: number;
  descripcionDeuda: string;
  descripcionDeudaHtml: null;
}

export class tipoDeuda {
  codigo: number;
  descripcion: string;
}

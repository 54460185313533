import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { AuthService } from "./auth/auth.service";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { cartLengthService } from "./home/store/cart/cart.component";
import { Subscription } from "rxjs";
import { AppServices } from "./services/app.services";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private subject = new Subject<any>();
  sendClickEvent() {
    this.subject.next();
  }
  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  private subject2 = new Subject<any>();
  sendResetSearchBar() {
    this.subject2.next();
  }

  getResetSearchBar(): Observable<any> {
    return this.subject2.asObservable();
  }
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "acrux-web-app";
  _displayLogin = false;
  @ViewChild("sidenav", { static: false }) sidenav: any;
  @ViewChild("sidenavbtn", { static: false }) sidenavbtn: any;
  searchForm: FormGroup;
  cartProducts: any;
  cartLength: number = 0;
  clickEventsubscription: Subscription;
  resetSearchBarEventSubscription: Subscription;
  isDirty: boolean | Observable<boolean> | Promise<boolean>;
  path: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private cartLengthService: cartLengthService,
    private appServices: AppServices
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        console.log(event);
        this.path = event.url;
        if (event.url != "/home/store") {
          this.buildSearchForm();
          localStorage.removeItem("search");
        }
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });

    this.clickEventsubscription = this.cartLengthService
      .getClickEvent()
      .subscribe(() => {
        this.loadCart();
      });

    this.resetSearchBarEventSubscription = this.sharedService
      .getResetSearchBar()
      .subscribe(() => {
        this.buildSearchForm();
      });
  }
  ngOnDestroy(): void {
    localStorage.removeItem("jwt");
  }

  ngOnInit(): void {
    this.authService.authStatus.subscribe((authStatus) => {
      const jwt = this.authService.getToken();
      setTimeout(
        () => ((this._displayLogin = !(jwt == null || jwt === "")), 0)
      );
    });

    this.buildSearchForm();
    this.loadCart();
  }

  get displayMenu() {
    return this._displayLogin;
  }

  clickMe() {
    this.sidenav.toggle();
  }

  close(reason: string) {
    this.sidenav.close();
  }

  sidenavtoggle() {
    this.sidenav.close();
    var element = document.getElementById("sidenavbtn");
    element.classList.remove("cdk-focused");
    element.classList.remove("cdk-program-focused");
  }

  buildSearchForm(): void {
    this.searchForm = this.fb.group({
      criterio: ["", []],
    });
  }

  searchProduct(submittedForm: FormGroup) {
    //localStorage.removeItem('search');
    localStorage.setItem("search", submittedForm.value.criterio);
    if (this.path === "/home/store") {
      console.log(this.path);
      this.sharedService.sendClickEvent();
    } else {
      this.sharedService.sendClickEvent();
      this.router.navigate(["/home/store"]);
    }
  }

  getCart() {
    this.cartLength = 0;
    if (
      localStorage.getItem("cart") == null ||
      localStorage.getItem("cart") === ""
    ) {
      this.cartProducts = "";
    } else {
      this.cartProducts = JSON.parse(localStorage.getItem("cart"));
      let i;
      for (i = 0; i < this.cartProducts.length; i++) {
        console.log(this.cartProducts);
        if (this.cartProducts[i].id_accion === 2) {
        }

        if (this.cartProducts[i].id_accion === 1) {
          this.cartLength = this.cartLength + this.cartProducts[i].cantidad;
          console.log(this.cartLength);
        }
      }
    }
  }

  loadCart() {
    if (this.authService.getUser() == "") {
      // debugger
      setTimeout(() => {
        this.loadCart();
      }, 1000);
      return;
    }
    let data = {
      NumQuery: "1",
      Id_empresa: this.authService.getCompanyCode(),
      Token: this.authService.getToken(),
      Usuario: this.authService.getUser(),
      Terminal: "Nombre PC",
      Cod_cliente: this.authService.getUserCode(),
    };
    return this.appServices.loadCart(data).subscribe((response) => {
      localStorage.setItem("cart", JSON.stringify(response));
      this.getCart();
    });
  }
}

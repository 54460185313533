import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { SharedService } from '../../app.component';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SideNavComponent implements OnInit {

  constructor(private cookieService: CookieService, private router: Router, private authService: AuthService, private sharedService: SharedService) { 
  }

  ngOnInit() {
  }

  logOut () {
    this.authService.logout();
    this.cookieService.deleteAll();
    this.router.navigate(['/']);
  }

  resetSearchBar() {
    // this.sharedService.sendResetSearchBar();
  }
}

import { Component, OnInit, Inject } from "@angular/core";
import { AuthService } from "../../../auth/auth.service";
import { AppServices } from "../../../services/app.services";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { saveAs } from "file-saver";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class cartLengthService {
  private subject = new Subject<any>();
  sendClickEvent() {
    this.subject.next();
  }
  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
}

export interface DialogData {
  products;
  amount;
}

@Component({
  selector: "cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["../store.component.scss"],
})
export class CartComponent implements OnInit {
  loading: boolean = false;
  codigo: number;
  private sub: any;
  product;
  productForm: FormGroup;
  cantidadMax;
  cartProducts;
  saveProducts: any = [];
  selectedProducts: any = [];
  cantidades;
  totalAmount: number = 0;
  searchForm: FormGroup;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private appServices: AppServices,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private sharedService: cartLengthService
  ) {}

  ngOnInit() {
    this.loading = true;
    if (
      localStorage.getItem("cart") == null ||
      localStorage.getItem("cart") === ""
    ) {
      this.loading = false;
      this.cartProducts = "";
    } else {
      this.loading = false;
      this.cartProducts = JSON.parse(localStorage.getItem("cart"));
      console.log(this.cartProducts);
      let i;
      for (i = 0; i < this.cartProducts.length; i++) {
        if (this.cartProducts[i].id_accion === 2) {
          if (!this.cartProducts[i].controlExistencia) {
            this.cartProducts[i].cantidad_Disponible = "";
          }
          this.saveProducts.push(this.cartProducts[i]);
        }

        if (this.cartProducts[i].id_accion === 1) {
          if (!this.cartProducts[i].controlExistencia) {
            this.cartProducts[i].cantidad_Disponible = "";
          }
          this.selectedProducts.push(this.cartProducts[i]);
          let precioProducto =
            this.cartProducts[i].precio_Venta * this.cartProducts[i].cantidad;
          this.totalAmount = this.totalAmount + precioProducto;
        }
        console.log(this.totalAmount);
      }
    }
    this.buildSearchForm();
  }

  buildSearchForm(): void {
    this.searchForm = this.fb.group({
      criterio: ["", []],
    });
  }

  searchProduct(submittedForm: FormGroup) {
    this.cookieService.set("search", "", -1);
    this.cookieService.set("search", submittedForm.value.criterio);
    //localStorage.setItem('search', submittedForm.value.criterio);
    this.router.navigate(["/home/store"]);
  }

  updateProduct(accion, cod, nombre, precio, fecha, impuestos, cantidad) {
    this.loading = true;
    let data = {
      NumQuery: 2,
      Id_empresa: this.authService.getCompanyCode(),
      Token: this.authService.getToken(),
      Usuario: this.authService.getUser(),
      Terminal: "Nombre PC",
      Cod_producto: cod,
      Cantidad: cantidad,
      Precio_Venta: precio,
      Producto: nombre,
      Fecha_Registro: fecha,
      Porcentaje_Impuestos: impuestos,
      Id_accion: accion,
      Cod_cliente: this.authService.getUserCode(),
    };

    this.appServices.storeCart(data).subscribe(
      (response) => {
        localStorage.setItem("cart", JSON.stringify(response));
        this.cartProducts = JSON.parse(localStorage.getItem("cart"));
        if (this.cartProducts.length === 0) {
          this.cartProducts = "";
          localStorage.setItem("cart", "");
        } else {
          this.selectedProducts = [];
          this.saveProducts = [];
          this.totalAmount = 0;
          let i;
          for (i = 0; i < this.cartProducts.length; i++) {
            if (this.cartProducts[i].id_accion === 2) {
              console.log(this.saveProducts);
              this.saveProducts.push(this.cartProducts[i]);
              console.log(this.saveProducts);
            }

            if (this.cartProducts[i].id_accion === 1) {
              console.log(this.selectedProducts);
              this.selectedProducts.push(this.cartProducts[i]);
              console.log(this.selectedProducts);
              let precioProducto =
                this.cartProducts[i].precio_Venta *
                this.cartProducts[i].cantidad;
              this.totalAmount = this.totalAmount + precioProducto;
            }
            console.log(this.totalAmount);
          }
        }
        this.sharedService.sendClickEvent();
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(error);
      }
    );
  }

  placeOrder() {
    this.loading = true;
    let data = {
      Id_empresa: this.authService.getCompanyCode(),
      Id_accion: 1,
      Token: this.authService.getToken(),
      Cod_cliente: this.authService.getUserCode(),
    };

    console.log(data);
    this.appServices
      .placeOrder(data)
      .subscribe((data) => this.downLoadFile(data, "text/pdf")),
      console.log(data),
      (error) => console.log("Error downloading the file."),
      () => console.info("OK");
  }

  downLoadFile(data: any, type: string) {
    var blob = new Blob([data], { type: type.toString() });
    var url = window.URL.createObjectURL(blob);
    console.log(blob);
    saveAs(blob, "orden-compra.pdf");
    this.cartProducts = "";
    this.selectedProducts = "";
    this.saveProducts = "";
    localStorage.setItem("cart", "");
    this.loading = false;
    this.sharedService.sendClickEvent();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: "60%",
      data: { products: this.selectedProducts, amount: this.totalAmount },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result.event);
      if (result.event === "Yes") {
        this.placeOrder();
      }
    });
  }
}

@Component({
  selector: "order-confirmation-poup",
  templateUrl: "order-confirmation-poup.html",
  styleUrls: ["../store.component.scss"],
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    // will log the entire data object
    console.log(this.data);
  }

  doAction() {
    this.dialogRef.close({ event: "Yes" });
  }

  onNoClick(): void {
    this.dialogRef.close({ event: "No" });
  }
}

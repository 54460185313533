import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { Company } from '../types';
import { AuthResponse } from '../home/pos/types/types';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;
  loginError = '';
  companies: Company[];
  loginForm: FormGroup;
  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.authService.logout();
    this.buildLoginForm();
  }

  buildLoginForm(): void {
    this.loginForm = this.fb.group({
      user: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(15)]],
      company: ['', [Validators.required]]
    })
  }

  authenticate(submittedForm: FormGroup) {
    this.loginError = '';
    this.loading = true;
    this.authService.authenticate(submittedForm.value.user, submittedForm.value.password).
      subscribe(companies => {
        this.companies = companies;
      }, error => {
        console.log(error);
        this.loginError = error;
      }).add(() => this.loading = false);
  }

  login(urlServidor: string, codigoEmpresa: string) {
    const submittedForm = this.loginForm;
    this.loginError = '';
    this.loading = true;
    this.authService.login2(submittedForm.value.user, submittedForm.value.password, urlServidor).
      subscribe((authResponse: AuthResponse) => {
        window.localStorage.setItem('ncfOpcional', authResponse.configGeneral.ncf_Opcional + '');
        window.localStorage.setItem('urlServidor', urlServidor)
        window.localStorage.setItem('codigoEmpresa', codigoEmpresa)
        window.localStorage.setItem('businessGroupCode', authResponse.business_Group_Code)
        if (authResponse.perfil_portal_web) {
          this.router.navigate(['/home/pos']);
        } else {
          this.router.navigate(['/home/store']);
        }
      }, error => {
        console.log(error);
        this.loginError = error;
      }).add(() => this.loading = false);
  }
}
